
<template>
  <div class="page-content">
    <div class="content">
      <h1 class="firstTitle">{{ firstTitle }}</h1>
      <h5 class="secondTitle">{{ secondTitle }}</h5>

      <div class="box">
        <div
          class="card"
          v-for="item in iconList1"
          :key="item.id"
          :style="item.id == 1 ? '' : 'background:#34476E'"
        >
          <h3 class="title" :style="item.id == 1 ? '' : 'color:#fff'">
            {{ item.title }}
          </h3>
          <div class="line"></div>
          <span
            class="price"
            :style="
              item.id == 1 ? '' : 'text-decoration: line-through;color: #FFFFFF'
            "
          >
            {{ item.price }}</span
          >

          <button
            class="btn"
            @click="user"
            :style="
              item.id == 1
                ? 'background: #E4E5EB;color: #222832'
                : 'background:#F28335;color: #FFFFFF'
            "
            :onclick="'return gtag_report_conversion(' + openUrl + ')'"
          >
            {{ item.button }}
          </button>

          <ul>
            <li
              v-for="li in item.content"
              :key="li"
              :style="item.id == 1 ? '' : 'color: #FFFFFF'"
            >
              {{ li }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../libs/api";
export default {
  name: "Price",
  components: {},
  created() {},
  computed: {},
  mounted() {},

  data() {
    return {
      openUrl: /localhost|dev/.test(location.origin)
        ? "'https://dev.tutordata.cn/'"
        : /uat/.test(location.origin)
        ? "'http://uat.tutordata.cn/'"
        : "'https://tutordata.cn/'",
      firstTitle: "邀您共赴大数据智能驱动之旅",
      secondTitle: "新品特惠，限时免费",
      iconList1: [
        {
          id: 1,
          title: "基础版",
          price: "0元",
          hasLine: false,
          hasColor: false,
          button: "免费使用",
          content: [
            "以下功能与服务，全部免费",
            "✓ 全端数据采集及技术支持",
            "✓ 用户行为事件可视化分析",
            "✓ 自定义报表看板",
            "✓ 分析结果数据导出",
            "✓ 数据监控预警系统",
            "✓ 用户分群",
          ],
          //   imgSrc: require("../assets/images/icon_01.png"),
        },

        {
          id: 2,
          title: "进阶版",
          hasLine: true,
          hasColor: true,
          price: "10,000元/年",
          button: "限时免费，立即体验",
          content: [
            "包含基础版所有功能，以及",
            "✓ 私有化部署",
            "✓ SQL查询平台",
            "✓ 原始数据全量导出",
            "✓ 渠道数据追踪",
            "✓ 智能运营平台",
          ],

          //   imgSrc: require("../assets/images/icon_01.png"),
        },
      ],
    };
  },
  methods: {
    background(type) {
      if (type == "warning") {
        this.$Message[type]({
          background: true,
          content: "登录过期,请重新登录",
        });
      }
    },
    //点击使用
    user() {
      let login = this.loginInfo.isLogin;
      if (login) {
        // 已登录
        api.loginTutor().then((res) => {
          if (res.data.status == 1) {
            // =-1001001
            let users = {
              token: res.data.fields.token,
              userId: res.data.fields.userId,
              userName: this.$cookies.get("userP"),
            };
            let userInfo =
              "?token=" +
              users.token +
              "&userId=" +
              users.userId +
              "&userName=" +
              users.userName;
            let url = /localhost|dev/.test(location.origin)
              ? "https://dev.tutordata.cn/"
              : /uat/.test(location.origin)
              ? "http://uat.tutordata.cn/"
              : "https://demo.tutordata.cn/";
            window.open(url + userInfo); //线上
          } else if (res.data.status == -1001001) {
            this.background("warning");
            this.$parent.loginOut();
          }
        });
      } else {
        // 未登录
        this.$emit("priceRegister", true);
      }
    },
  },
};
</script>

<style scoped>
.firstTitle {
  margin-top: 3rem;
  width: 575px;
  height: 56px;
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222832;
  line-height: 56px;
  letter-spacing: 4px;
}
.secondTitle {
  margin: 1rem 0;
  width: 162px;
  height: 34px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222832;
  line-height: 34px;
}
.title {
  margin-top: 1rem;
  width: 96px;
  height: 45px;
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  /* font-weight: 500;
  color: #222832; */
  line-height: 45px;
  font-weight: 540;
  color: #222832;
  margin-top: 51px;
  margin-bottom: 25px;
}
.price {
  margin: 9px 0 13px 0;
  /* width: 32px; */
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222832;
  line-height: 28px;
}
.btn {
  width: 264px;
  height: 62px;
  background: #e4e5eb;
  border-radius: 4px;
  color: #fff;
  border: none;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 28px;
  margin: 16px 0 70px;
}
.line {
  border-top: 1px solid #ccc;
  margin: 1rem 0 0.6rem;
  width: 340px;
  height: 1px;
}
.hasline {
  text-decoration: line-through;
}
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box {
  display: flex;
  width: 860px;
  /* justify-content: space-around; */
}
.card {
  width: 400px;
  height: 645px;
  border-radius: 4px;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 4.35rem;
  /* border: 1px solid rgb(88, 86, 86); */
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
}
ul {
  margin-bottom: 36px;
}
ul li {
  list-style: none;
  margin-bottom: 0.2rem;
  color: #222832;
  font-size: 15px;
  font-family: LucidaGrande;
  line-height: 35px;
}
ul li:nth-child(1) {
  width: 180px;
  height: 21px;
  font-size: 15px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 7px;
}
</style>
