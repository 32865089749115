<template>
  <section class="con-wrap">
    <h1 class="section-title">{{ sectionTitle }}</h1>
    <div class="tab-list">
      <div class="tab-title">
        <div
          v-for="(tab, index) in tabs"
          :key="tab.imgSrc"
          :class="index === currentIndex ? 'title-item-checked' : 'title-item'"
          @click="currentIndex = index"
        >
          {{ tab.title }}
        </div>
      </div>
      <div
        v-for="(tab, index) in tabs"
        v-show="index === currentIndex"
        :key="tab._uid"
        class="tab-item"
      >
        <p class="item-text">{{ tab.text }}</p>
        <img :src="tab.imgSrc" :alt="tab.title" class="item-img" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "img-tab-section",
  props: {
    sectionTitle: String,
    tabs: Array,
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
};
</script>

<style scoped>
.section-title {
  margin: 3.2rem 0;
}
.tab-list {
  width: 27rem;
  margin: 0 auto;
  padding-bottom: 4.5rem;
}

.tab-title {
  margin-bottom: 3rem;
  display: flex;
}

.tab-title div {
  width: 6.75rem;
  height: 2.2rem;
  font-size: 0.7rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 2.2rem;
  text-align: center;
  cursor: pointer;
}

.title-item {
  background: rgba(255, 255, 255, 1);
  border: 0.05rem solid rgba(242, 131, 53, 1);
  border-left: none;
  color: rgba(242, 131, 53, 1);
}

.title-item:first-of-type {
  border-left: 0.05rem solid rgba(242, 131, 53, 1);
}

.title-item-checked {
  background: rgba(242, 131, 53, 1);
  border: 0.05rem solid rgba(242, 131, 53, 1);
  border-left: none;
  color: rgba(255, 255, 255, 1);
}

.item-text {
  margin-bottom: 1rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: rgba(34, 40, 50, 1);
  line-height: 1.7rem;
  text-align: center;
}

.item-img {
  width: 27rem;
}
</style>
