<template>
  <section class="con-wrap">
    <div class="opcon">
      <h1 class="section-title">{{ thirdTitle }}</h1>
      <p class="text">{{ thirdDescribe }}</p>

      <div class="boxBottom">
        <Carousel
          class="Carousel"
          autoplay
          v-model="num"
          loop
          dots="none"
          arrow="never"
          autoplay-speed:4000
        >
          <CarouselItem v-for="(item, index) in iconList" :key="index">
            <img class="img" :src="item.img" alt="" />
          </CarouselItem>
        </Carousel>

        <ul>
          <li
            v-for="(item, index) in iconList"
            :key="index"
            v-bind:class="{ active: index == num }"
            @click="tab(index)"
          >
            <div class="rightTxt">
              <div class="line" v-bind:class="{ color: index == num }"></div>
              <div>
                <h3>{{ item.title }}</h3>
                <p class="p-txt">{{ item.text }}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    thirdTitle: String,
    thirdDescribe: String,
    iconList: Array,
  },
  data() {
    return {
      num: 0,
    };
  },
  methods: {
    tab(index) {
      this.num = index;
    },
  },
};
</script>

<style scoped>
.boxBottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.6rem 0 6rem;
}
ul {
  list-style: none;
}

.line {
  width: 0.3rem;
  height: 7rem;
  background: #e1e8ff;
  margin-right: 1.1rem;
}
.color {
  background: #f28335;
}
.rightTxt {
  display: flex;
  align-items: center;
}
.rightTxt:hover {
  cursor: pointer;
}
/* //// */
.opcon {
  margin: auto;
}
.tab-list {
  width: 27rem;
  margin: 0 auto;
  padding-bottom: 4.5rem;
}
.text {
  font-size: 1.05rem;
  text-align: center;
}

.img,
.Carousel {
  width: 22.65rem;
  height: 28.1rem;
}
h3 {
  font-size: 1rem;
}
.p-txt {
  font-size: 0.75rem;
  font-weight: 400;
  margin: 0.5rem 0 1rem;
}
</style>
