<template>
  <section class="con-wrap">
    <h1 class="section-title">{{ sectionTitle }}</h1>
    <div :class="conCenter && 'con-center'" class="icon-list">
      <div v-for="icon in iconList" :key="icon._uid" class="icon-item">
        <img :src="icon.imgSrc" :alt="icon.text" />
        <h3>{{ icon.title }}</h3>
        <p>{{ icon.text }}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "icon-list-section",
  props: {
    sectionTitle: String,
    iconList: Array,
    conCenter: Boolean,
  },
};
</script>

<style scoped>
.section-title {
  margin: 3.2rem 0;
}
.icon-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
}

.con-center {
  justify-content: center;
}

.con-center .icon-item {
  margin-right: 1.5rem;
}

.icon-item {
  width: 17.49rem;
  height: 16.7rem;
  margin-bottom: 1.5rem;
  padding: 3rem 1.7rem 0;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0rem 0.25rem 0.75rem 0rem rgba(116, 125, 132, 0.16);
  border-radius: 0.5rem;
  position: relative;
}

.icon-item img {
  width: 4.15rem;
  display: block;
  margin: 0 auto 1.4rem;
}

.icon-item h3 {
  height: 1.65rem;
  margin-bottom: 0.45rem;
  font-size: 1.2rem;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: rgba(33, 43, 54, 1);
  line-height: 1.45rem;
}

.icon-item p {
  height: 1.2rem;
  font-size: 0.75rem;
  font-family: OpenSans;
  color: rgba(99, 115, 129, 1);
  line-height: 1.2rem;
}
</style>
