<template>
  <div class="page-content">
    <img-text-section
      :bg-img-src="firstImgSrc"
      :section-title="firstTitle"
      :section-text="textList"
      :section-btn="sectionBtn"
      @Register="Register"
    >
    </img-text-section>

    <img-tab-section :section-title="secondTitle" :tabs="tabs">
    </img-tab-section>

    <icon-list-section :section-title="thirdTitle" :icon-list="iconList">
    </icon-list-section>
  </div>
</template>

<script>
import ImgTextSection from "@c/ImgTextSection";
import ImgTabSection from "@c/ImgTabSection";
import IconListSection from "@c/IconListSection";

export default {
  name: "analysis-platform",
  components: {
    ImgTextSection,
    ImgTabSection,
    IconListSection,
  },
  data() {
    return {
      firstTitle: "数据分析平台",
      textList: ["提供多种分析模型，洞察用户行为，让数据分析从此简单便捷。"],
      firstImgSrc: require("../assets/images/bg_analysis_1.png"),
      sectionBtn: {
        text: "体验Demo",
        url: "#",
      },
      secondTitle: "深入业务场景，满足多样分析需求",
      tabs: [
        {
          title: "用户增长",
          text: "将用户渠道来源标签化，通过用户后续行为转化情况，直观衡量渠道质量，找寻优质渠道，提升ROI。",
          imgSrc: require("../assets/images/bg_analysis_2.png"),
        },
        {
          title: "运营",
          text: "深度洞悉用户行为，通过用户分群实现个性化营销，实现精细化运营。",
          imgSrc: require("../assets/images/bg_analysis_3.png"),
        },
        {
          title: "产品",
          text: "通过实时数据反馈和多维度分析，快速评估产品迭代效果，改善用户体验，提升用户核心转化。",
          imgSrc: require("../assets/images/bg_analysis_4.png"),
        },
        {
          title: "决策",
          text: "通过概览可统一掌握各部分数据指标，业务情况清晰透明，助力决策。",
          imgSrc: require("../assets/images/bg_analysis_5.png"),
        },
      ],
      thirdTitle: "分析平台特性",
      iconList: [
        {
          title: "自定义概览",
          text: "实时监控常用分析数据，灵活定制，可实现权限管理",
          imgSrc: require("../assets/images/icon_analysis_1.png"),
        },
        {
          title: "数据分析",
          text: "多维度多指标交叉分析",
          imgSrc: require("../assets/images/icon_analysis_2.png"),
        },
        {
          title: "用户分群",
          text: "精准定位不同用户群，定向营销",
          imgSrc: require("../assets/images/icon_analysis_3.png"),
        },
        {
          title: "用户属性分析",
          text: "深度了解用户是谁？",
          imgSrc: require("../assets/images/icon_analysis_4.png"),
        },
        {
          title: "预警模块",
          text: "满足条件即触发预警，不错过突发情况，及时解决问题避免损失。",
          imgSrc: require("../assets/images/icon_analysis_5.png"),
        },
        {
          title: "自定义SQL查询",
          text: "提供查询引擎，满足个性化需求",
          imgSrc: require("../assets/images/icon_analysis_6.png"),
        },
      ],
    };
  },
  methods: {
    Register(v) {
      this.$emit("priceRegister", v);
    },
    logOut() {
      this.$parent.loginOut();
    },
  },
};
</script>

<style scoped>
</style>
