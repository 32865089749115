<template>
  <section class="img-text-section">
    <div class="con-wrap">
      <img class="section-img" :src="bgImgSrc" :alt="sectionTitle" />
      <div class="section-con">
        <h1 class="section-right-title">{{ sectionTitle }}</h1>
        <p v-for="text in sectionText" :key="text._uid" class="section-text">
          {{ text }}
        </p>

        <Button
          data-agl-cvt="6"
          @click="demo"
          class="section-btn"
          :loading="loading"
          v-preventReClick="5000"
          :onclick="'return gtag_report_conversion(' + openUrl + ')'"
        >
          {{ sectionBtn.text }}
        </Button>
      </div>
    </div>
  </section>
</template>

<script>
import api from "../libs/api";

export default {
  name: "img-text-section",
  components: {},
  data() {
    return {
      openUrl: /localhost|dev/.test(location.origin)
        ? "'https://dev.tutordata.cn/'"
        : /uat/.test(location.origin)
        ? "'http://uat.tutordata.cn/'"
        : "'https://tutordata.cn/'",
      loading: false,
    };
  },
  props: {
    sectionTitle: String,
    sectionText: Array,
    sectionBtn: Object,
    bgImgSrc: String,
  },
  methods: {
    background(type) {
      if (type == "warning") {
        this.$Message[type]({
          background: true,
          content: "登录过期,请重新登录",
        });
      }
    },
    demo() {
      let login = this.loginInfo.isLogin;

      if (login) {
        // 已登录
        api.loginTutor().then((res) => {
          if (res.data.status == 1) {
            this.loading = true;
            setTimeout(() => {
              this.loading = false;
            }, 5000);
            // =-1001001
            let users = {
              token: res.data.fields.token,
              userId: res.data.fields.userId,
              userName: this.$cookies.get("userP"),
            };
            let userInfo =
              "?token=" +
              users.token +
              "&userId=" +
              users.userId +
              "&userName=" +
              users.userName;
            let url = /localhost|dev/.test(location.origin)
              ? "https://dev.tutordata.cn/"
              : /uat/.test(location.origin)
              ? "http://uat.tutordata.cn/"
              : "https://demo.tutordata.cn/";
            window.open(url + userInfo); //线上
          } else if (res.data.status == -1001001) {
            this.background("warning");
            this.$parent.logOut();
          }
        });
      } else {
        // 未登录
        this.$emit("Register", true);
      }
    },
  },
};
</script>

<style scoped>
.img-text-section {
  width: 100%;
  padding: 1.4rem 0 2.8rem;
  background: rgba(248, 249, 255, 1);
}

.img-text-section .con-wrap {
  display: flex;
}

.section-img {
  width: 27rem;
  height: 23rem;
  display: block;
}

.section-con {
  margin-left: 1.5rem;
  display: block;
  position: relative;
}

.section-right-title {
  height: 2.8rem;
  margin: 3.7rem 0 3rem;
  font-size: 2rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(34, 40, 50, 1);
  line-height: 2.8rem;
  letter-spacing: 4px;
}

.section-text {
  height: 1.7rem;
  font-size: 0.95rem;
  font-weight: 400;
  color: rgba(87, 90, 95, 1);
  line-height: 1.7rem;
}

.section-btn {
  width: 9.3rem;
  height: 3.1rem;
  background: rgba(242, 131, 53, 1);
  box-shadow: 0rem 0.25rem 0.5rem 0rem rgba(178, 95, 36, 0.24);
  border-radius: 0.2rem;
  font-size: 1rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  line-height: 3.1rem;
  text-align: center;
  text-decoration: none;
  display: block;
  position: absolute;
  left: 0;
  bottom: 4.65rem;
}
</style>