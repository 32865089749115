<template>
  <div class="page-content">
    <img-text-section
      :bg-img-src="firstImgSrc"
      :section-title="firstTitle"
      :section-text="textList"
      :section-btn="sectionBtn"
      @Register="Register"
    >
    </img-text-section>

    <img-tab-section :section-title="secondTitle" :tabs="tabs">
    </img-tab-section>
    <img-opertion
      :thirdTitle="thirdTitle"
      :thirdDescribe="thirdDescribe"
      :iconList="iconList"
    >
    </img-opertion>

    <!-- <icon-list-section :section-title="thirdTitle" :icon-list="iconList">
    </icon-list-section> -->
    <!-- <img-opertion :section-title="secondTitle" :tabs="tabs"> </img-opertion> -->
  </div>
</template>

<script>
import ImgTextSection from "@c/ImgTextSection";
import ImgTabSection from "@c/ImgTabSection";
import IconListSection from "@c/IconListSection";
import ImgOpertion from "@c/ImgOpertion";

export default {
  name: "analysis-platform",
  components: {
    ImgTextSection,
    ImgTabSection,
    IconListSection,
    ImgOpertion,
  },
  data() {
    return {
      firstTitle: "精细便捷高效的自动化智能运营系统",
      textList: [
        "能够基于用户行为、属性等数据精准定位目标人群，实现活动创建、执行、效果分析完整运营流程自动化，助力运营人员完成指标，提高运营效率。",
      ],

      firstImgSrc: require("../assets/images/operation/top.png"),
      sectionBtn: {
        text: "体验Demo",
        url: "#",
      },
      secondTitle: "智能运营实现闭环",
      tabs: [
        {
          title: "策略制定",

          text: "灵活定制活动策略，选择运营计划类型，设定计划目标与起止时间并推动活动快速落地。",
          imgSrc: require("../assets/images/operation/tactics.png"),
        },
        {
          title: "用户筛选",
          text: "通过用户属性和之前的行为提取出关键目标人群，精准触达，实现有的放矢。",
          imgSrc: require("../assets/images/operation/user.png"),
        },
        {
          title: "多形式触达",
          text: "通过弹窗、App推送、短信、Webhook等多种用户触达通道，灵活自定义推送内容，与目标用户完成有效互动。",
          imgSrc: require("../assets/images/operation/touth.png"),
        },
        {
          title: "效果实时分析",
          text: "活动效果通过数据看板实时展现，方便运营人员及时对运营策略各个阶段的结果做出判断，为下一步的调整提供数据支撑。",
          imgSrc: require("../assets/images/operation/realTime.png"),
        },
      ],
      thirdTitle: "多种实现形式，与用户高效互动",
      thirdDescribe: "精细化场景，自主控制触达频次，动态内容设置",

      iconList: [
        {
          title: "弹窗",
          text: "达成及时高效互动，支持App内、H5页面弹窗",
          img: require("../assets/images/operation/t.png"),
        },
        {
          title: "App推送",
          text: "便捷推送通道接入，支持自定义跳转",
          img: require("../assets/images/operation/a.png"),
        },
        {
          title: "短信",
          text: "个性化营销信息",
          img: require("../assets/images/operation/d.png"),
        },
        {
          title: "Webhook",
          text: "可以支持更多自定义的营销行为，满足研发需求",
          img: require("../assets/images/operation/w.png"),
        },
      ],
    };
  },
  methods: {
    Register(v) {
      this.$emit("priceRegister", v);
    },
    logOut() {
      this.$parent.loginOut();
    },
  },
};
</script>

<style scoped>
</style>
